<template>
    <section id="images" ref="images" :class="[
      'images-section',
      isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
      'py-5 min-vh-100 rounded'
    ]">
      <div class="container text-center d-flex flex-column justify-content-center h-100 pt-3">
        <h3 :class="[
          isBackgroundPrimary ? 'text-info' : 'text-dark',
        ]">{{ title }}</h3>
        <h1 :class="[
          isBackgroundPrimary ? 'text-light' : 'text-primary',
        ]">{{ subtitle }}</h1>
        <b-row class="mt-4">
          <b-col class="mt-2" md="6" v-for="(image, index) in images" :key="index">
            <b-img :src="image" :alt="`Screenshot ${index + 1}`" fluid></b-img>
          </b-col>
        </b-row>
      </div>
    </section>
  </template>
  
  <script>
  import { BImg, BRow, BCol } from 'bootstrap-vue';
  
  export default {
    name: 'FunctionalitiesSection',
    components: {
      BImg,
      BRow,
      BCol
    },
    props: {
      isBackgroundPrimary: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'FONCTIONNALITÉS'
      },
      subtitle: {
        type: String,
        default: 'Facile à utiliser'
      },
      images: {
        type: Array,
        default: () => [
          require('@/assets/images/banner/banner-19.jpg'),
          require('@/assets/images/banner/banner-11.jpg'),
          require('@/assets/images/banner/banner-12.jpg')
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .images-section .b-img {
    margin-top: 20px;
  }
  
  .images-section h1 {
    font-size: 2.5rem;
  }
  
  @media (max-width: 768px) {
    .images-section h1 {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .images-section h1 {
      font-size: 1.5rem;
    }
  }
  </style>
  